import React, { Component, Fragment } from 'react'
import { Home } from 'react-feather'
import { Layout, SEO, HMOSlider } from '../../components/common'
import { Container, Row, Col, Breadcrumb, Image, Card } from 'react-bootstrap'
import { FacebookShareButton, TwitterShareButton } from 'react-share'

class ShowMedicalDept extends Component {
    render() {
        const pageContext = this.props.pageContext
        const { favicon } = pageContext.topNav
        const { banner, name, description, members, slug } = this.props.pageContext.medicalDepartment
        return (
            <>
                <SEO title={"Department - " + name } favicon={favicon}/>
                <Layout activeNav='about-us' data={pageContext.topNav}>
                    <Container className="my-4 md-mt-10 sm-mt-7">

                        <Breadcrumb className="breadcrumb-wrapper mx-0">
                            <Breadcrumb.Item href="/">
                                <Home className="text-violet mr-2" size={14} />
                                Home
						</Breadcrumb.Item>
                            <Breadcrumb.Item href="/about-us/company-profile">
                                About Us
						</Breadcrumb.Item>
                            <Breadcrumb.Item href="/about-us/medical-departments">
                                Departments
                                </Breadcrumb.Item>
                            <Breadcrumb.Item active>{name}</Breadcrumb.Item>
                        </Breadcrumb>

                        <Row>
                            <Col lg={4} md={12} sm={12} className="my-3">
                                <Image src={banner} alt={slug} className="image-xxxl" />
                            </Col>
                            <Col lg={8} md={12} sm={12} className="my-3">
                                <Card>
                                    <Card.Title className="m-4">
                                        <h3>
                                            <strong>{name}</strong>
                                        </h3>
                                    </Card.Title>
                                    <div className="m-4" dangerouslySetInnerHTML={{ __html: description }} />
                                    {members && members.length !== 0
                                        ? <Fragment>
                                            <h5 className="mx-4 mb-2">Members:</h5>
                                            {members.map((member, i) => <h6 key={i} className="ml-4">{ member.prefix + " " + member.name }</h6>)}
                                        </Fragment>
                                        : null}
                                    <div className="border-dashed-bottom my-2" />

                                    <Card.Body>
                                        <h5 className="mb-2">Share this department to</h5>
                                        <Row>
                                            <Col>
                                              <FacebookShareButton
                                                    url={process.env.MH_URL+"/about-us/medical-department/"+slug}
                                                    className="btn btn-outline-light btn-lg btn-block d-flex flex-row align-items-center border text-dark"
                                                >
                                                    <Image src={require("../../images/facebook-logo.png")} className="image-h-icon" />
                                                    <span className="w-100 text-center">
                                                        Facebook
												</span>
                                                </FacebookShareButton>
                                            </Col>
                                            <Col>
                                              <TwitterShareButton
                                                    url={process.env.MH_URL+"/about-us/medical-department/"+slug}
                                                    className="btn btn-outline-light btn-lg btn-block d-flex flex-row align-items-center border text-dark"
                                                >
                                                    <Image src={require("../../images/twitter-logo.png")} className="image-h-icon" />
                                                    <span className="w-100 text-center">
                                                        Twitter
												</span>
                                                </TwitterShareButton>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    <HMOSlider data={pageContext.accreditedHmos} />
                </Layout>
            </>
        )
    }
}

export default ShowMedicalDept
